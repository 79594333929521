import React from "react";
import { connect } from 'react-redux';
import * as Selectors from '../../selectors';
import { WebRtcContext } from '../WebRtc/WebRtcProvider';

const CallPanel = ({
  panelId,
  workItemId,
  callControlUrl,
  accessToken,
  conversationId,
  directoryUrl,
  customerApiUrl,
  conversationApiUrl,
}) => {
  const fragmentRef = React.useRef();

  const { isRinging, webRtcClient } = React.useContext(WebRtcContext);

  React.useEffect(
    () => {
      const onAnswer = () => webRtcClient.answer();
      fragmentRef.current?.addEventListener("onanswer", onAnswer);

      return () => fragmentRef.current?.removeEventListener(onAnswer);
    },
    [],
  );


  console.log("CallFragment ConversationId: ", conversationId);
  return <callcontrol-fragment
    id={panelId}
    conversationId={conversationId}
    workItemId={workItemId}
    apiUrl={callControlUrl}
    token={accessToken}
    ref={fragmentRef}
    style={{ "height": "calc(100% - 30px)" }}
    directoryApiUrl={directoryUrl}
    customerApiUrl={customerApiUrl}
    conversationApiUrl={conversationApiUrl}
    answerable={isRinging}
  />;
};

const mapStateToProps = (state, ownProps) => {
  const { panelId, workItem } = ownProps;
  const conversationId = Selectors.getDataForCallPanel(state, panelId);
  const callControlUrl = Selectors.getSIPConnectorUrl(state) + "/hubs/agenthub";
  const accessToken = Selectors.backEndAccessToken(state);
  const directoryUrl = Selectors.getAksApiUrl(state) + "/directory";
  const customerApiUrl = Selectors.getAksApiUrl(state) + "/customer-manager/v2";
  const conversationApiUrl = Selectors.getAksApiUrl(state) + "/conversation-monitor";
  return {
    workItemId: workItem.workItemId,
    callControlUrl,
    accessToken,
    directoryUrl,
    customerApiUrl,
    conversationId,
    conversationApiUrl
  };
};

export default connect(mapStateToProps)(CallPanel);
