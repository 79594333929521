import VirtualConversationFragment from "@buzzeasy/virtual-conversation-fragment-2";
import { notification } from 'antd';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as Selectors from '../../selectors';
import { formatTimeSpan } from '../../services/datetime';

function toDate(date) {
    return typeof date === 'string' ? new Date(date) : date;
}

const formattingApi = {
    formatDate: d => toDate(d).toDateString(),
    formatTime: d => toDate(d).toTimeString(),
    formatDateTime: d => toDate(d).toString(),
    formatTimeSpan,
};

class VirtualConversationPanel extends Component {

    render() {
        const {
            conversationId,
            token,
            apiUrl,
        } = this.props;

        return <div style={{ "height": "calc(100% - 30px)", "zIndex": "0" }}>
            <VirtualConversationFragment
                token={token}
                apiBaseUrl={apiUrl}
                conversationId={conversationId}
                isFocused={true}
                onFocusRequested={() => {/* do nothing */ }}
                formattingApi={formattingApi}
                notificationApi={notification}
            />
        </div>;
    }

    static propTypes = {
        conversationid: PropTypes.string,
        token: PropTypes.string.isRequired,
        apiUrl: PropTypes.string.isRequired,
        panelId: PropTypes.string.isRequired,
    };
}

const mapStateToProps = (state, ownProps) => {
    const { customerId, conversationId } = Selectors.getDataForCustomerPanel(state, ownProps.panelId);
    const token = Selectors.backEndAccessToken(state);
    const apiUrl = Selectors.getAksApiUrl(state);
    return {
        token,
        apiUrl,
        conversationId,
    };
};

export default connect(mapStateToProps)(VirtualConversationPanel);
