import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Selectors from '../../selectors';

import './SupervisorPanel.css';

class SupervisorPanel extends Component {

	render() {
		const { portalUrl } = this.props;
		let betaPortalReportingUrl = `${portalUrl}/reports/teams`;
		let docsUrl = "https://docs.buzzeasy.com/supervisor/index.html";
		return (
			<div className={'outer-div'}>
				<div className={'supervisor-message'}> Legacy Supervisor Dashboard retired in February 2023. Try out our <a className={'suppervisor-link'} href={betaPortalReportingUrl} target="_blank">new portal reports</a>  or <a className={'suppervisor-link'} href={docsUrl} target="_blank">learn more!</a></div>
			</div >
		);
	}

	static propTypes = {
		conversationId: PropTypes.string
	};
}

const mapStateToProps = () => {
	const portalUrl = Selectors.getPortalUrl();
	return {
		portalUrl
	}

};

export default connect(mapStateToProps)(SupervisorPanel);
