import { defineMessages } from 'react-intl';

import API from '../../api/Api';

const messages = defineMessages({

	[`InteractionTab_${API.panel.types.chat}`]: {
		defaultMessage: 'Chat',
		id: 'InteractionTab_chat',
		description: 'Chat tab label'
	},

	[`InteractionTab_${API.panel.types.voice}`]: {
		defaultMessage: 'Call',
		id: 'InteractionTab_voice',
		description: 'Voice tab label'
	},

	[`InteractionTab_${API.panel.types.test}`]: {
		defaultMessage: 'Test',
		id: 'InteractionTab_test',
		description: 'Test tab label'
	},

	[`InteractionTab_${API.panel.types.other}`]: {
		defaultMessage: 'Interactions',
		id: 'InteractionTab_any',
		description: 'Interactions tab label / select from available interactions'
	},

	[`InteractionTab_${API.panel.types.incoming}`]: {
		defaultMessage: 'Waiting for interaction',
		id: 'InteractionTab_incoming',
		description: 'Waiting for interaction tab label'
	},

	[`InteractionTab_${API.panel.types.startShift}`]: {
		defaultMessage: 'Start shift',
		id: 'InteractionTab_startShift',
		description: 'Start shift interaction tab label'
	},

	[`InteractionTab_${API.panel.types.break}`]: {
		defaultMessage: 'Break',
		id: 'InteractionTab_break',
		description: 'Break tab label'
	},

	[`InteractionTab_${API.panel.types.cqManager}`]: {
		defaultMessage: 'Cherry Pick List',
		id: 'InteractionTab_cqManager',
		description: 'CQ Service tab label'
	},

	[`InteractionTab_${API.panel.types.pendingLogout}`]: {
		defaultMessage: 'Pending Logout',
		id: 'InteractionTab_pendingLogout',
		description: 'Pending logout tab label'
	},

	[`InteractionTab_${API.panel.types.pendingBreak}`]: {
		defaultMessage: 'Pending Break',
		id: 'InteractionTab_pendingBreak',
		description: 'Pending break tab label'
	},

	[`InteractionTab_${API.panel.types.loadingScreen}`]: {
		defaultMessage: 'loadingScreen',
		id: 'InteractionTab_loadingScreen',
		description: 'Loading screen for an unknown interaction'
	},

	[`InteractionTab_${API.panel.types.emptyWorkItem}`]: {
		defaultMessage: 'New Task', // ...since we call it 'Task' on the UI
		id: 'InteractionTab_emptyWorkItem',
		description: 'Panel that contains the available actions that can be initiated by the agent'
	},

	[`InteractionTab_${API.panel.types.userSettings}`]: {
		defaultMessage: 'User Settings',
		id: 'InteractionTab_userSettings',
		description: 'userSettings'
	},

	[`InteractionTab_${API.panel.types.dialpad}`]: {
		defaultMessage: 'New voice call',
		id: 'InteractionTab_dialpad',
		description: 'dialpad'
	},

	[`InteractionTab_${API.panel.types.customerview}`]: {
		defaultMessage: 'Customer Data',
		id: 'InteractionTab_customerview',
		description: 'Customer panel showing informations like email address, phonenumber etc.'
	},

	[`InteractionTab_${API.panel.types.email}`]: {
		defaultMessage: 'Email',
		id: 'InteractionTab_email',
		description: 'Email panel.'
	},

	[`InteractionTab_${API.panel.types.callLookup}`]: {
		defaultMessage: 'New Call',
		id: 'InteractionTab_callLookup',
		description: 'callLookup panel.'
	},

	[`InteractionTab_${API.panel.types.emailLookup}`]: {
		defaultMessage: 'New Email',
		id: 'InteractionTab_emailLookup',
		description: 'emailLookup panel.'
	},

	[`InteractionTab_${API.panel.types.genericLookup}`]: {
		defaultMessage: 'Contact lookup',
		id: 'InteractionTab_genericLookup',
		description: 'genericLookup panel.'
	},

	[`InteractionTab_${API.panel.types.teamsCall}`]: {
		defaultMessage: 'New Call',
		id: 'InteractionTab_teamsCall',
		description: 'teamsCall panel.'
	},
	[`InteractionTab_${API.panel.types.closedCall}`]: {
		defaultMessage: 'Closed Conversation',
		id: 'InteractionTab_closedCall',
		description: 'closedCall panel.'
	},
	[`InteractionTab_${API.panel.types.scripting}`]: {
		defaultMessage: 'scripting',
		id: 'InteractionTab_scripting',
		description: 'scripting panel.'
	},
	[`InteractionTab_${API.panel.types.supervisor}`]: {
		defaultMessage: 'Supervisor Dashboard',
		id: 'InteractionTab_supervisor',
		description: 'supervisor panel.'
	},
	[`InteractionTab_${API.panel.types.campaign}`]: {
		defaultMessage: 'Campaign',
		id: 'InteractionTab_campaign',
		description: 'Campaign tab label'
	}
});

export default messages;