import update from "immutability-helper";
import _ from "lodash";
import { createReducer } from "redux-act";

import * as Actions from "../../actions";
import API from "../../api/Api";
import { GRID_LAYOUTS } from "../../data/grid";

export default createReducer(
	{
		[Actions.changePanelSetLayout]: (state, payload) => {
			return update(state, {
				[payload.panelSetId]: {
					[API.panelSet.layout]: {
						$set: GRID_LAYOUTS[payload.layoutId]
					}
				}
			});
		},

		[Actions.updateLayout]: (state, payload) => {
			const { panelSetId, grid, stage } = payload;
			let newState = state;

			if (!stage) {
				newState = update(newState, {
					[panelSetId]: {
						[API.panelSet.layout]: {
							id: {
								$set: state[panelSetId][API.panelSet.layout].id
							},
							grid: {
								$set: grid
							}
						}
					}
				});
			}

			return newState;
		},

		/*
	Creates the dashboard panelSet. Used when initializing and there is no open work items appended to the user
	panel.js also has a reducer for this, which creates the startshift panel
	*/
		[Actions.loadInitialElements]: (state, payload) => {
			const newState = { ...state };

			const dashboard = {
				[API.panelSet.id]: API.panelSet.ids.dashboard,
				[API.panelSet.status]: API.panelSet.statuses.dashboard,
				[API.panelSet.layout]: {},
				[API.panelSet.panels]: [
					{
						[API.panelGroup.id]: API.panel.types.startShift,
						[API.panelGroup.panels]: [API.panel.types.startShift]
					}
				]
			};

			newState[API.panelSet.ids.dashboard] = dashboard;

			if (payload.isSupervisor) {
				newState[API.panelSet.ids.supervisor] = {
					[API.panelSet.id]: API.panelSet.ids.supervisor,
					[API.panelSet.status]: API.panelSet.statuses.supervisor,
					[API.panelSet.layout]: {},
					[API.panelSet.panels]: [
						{
							[API.panelGroup.id]: API.panel.ids.supervisor,
							[API.panelGroup.panels]: [API.panel.ids.supervisor]
						}
					]
				};
			}

			return newState;
		},

		[Actions.activateTab]: (state, payload) => {
			const { panelSetId, panelId } = payload;

			const panelIndex = _.findIndex(
				state[panelSetId][API.panelSet.panels],
				group => _.includes(group[API.panel.panels], panelId)
			);
			const panel = state[panelSetId][API.panelSet.panels][panelIndex];

			let newState = state;
			if (panel[API.panel.active] !== panelId) {
				newState = update(newState, {
					[panelSetId]: {
						[API.panelSet.panels]: {
							[panelIndex]: {
								[API.panel.active]: {
									$set: panelId
								}
							}
						}
					}
				});
			}

			return newState;
		},

		[Actions.addPanelToPanelSet]: (state, payload) => {

			const { panelSetId, panelType } = payload;
			const newState = { ...state };

			const panelId = panelSetId + "_" + panelType;
			newState[panelSetId] = { ...newState[panelSetId] }
			newState[panelSetId][API.panelSet.panels] = newState[panelSetId][API.panelSet.panels].slice()
			newState[panelSetId][API.panelSet.panels].push({
				[API.panelGroup.id]: panelId,
				[API.panelGroup.panels]: [panelId],
				[API.panelGroup.active]: panelId
			});
			return newState;
		},
		/**
			Adds the scripting panel
		*/
		[Actions.ScriptingUrlReceived]: (state, payload) => {

			const { workItemId } = payload;
			const newState = { ...state };
			const panelSetId = workItemId;
			const panelId = panelSetId + "_scripting";
			newState[panelSetId] = { ...newState[panelSetId] }
			newState[panelSetId][API.panelSet.panels] = newState[panelSetId][API.panelSet.panels].slice()
			const panels = newState[panelSetId][API.panelSet.panels];
			const newPanelItem = {
				[API.panelGroup.id]: panelId,
				[API.panelGroup.panels]: [panelId],
				[API.panelGroup.active]: panelId
			};

			if (!panels.some(p => p[API.panelGroup.id] === panelId)) {
				if (panels.length === 2) {
					panels.push(panels[1]);
					panels[1] = newPanelItem;
					newState[panelSetId][API.panelSet.layout] = {
						"grid": [{ w: 0.5, h: 0.5 }, { w: 0.5, h: 0.5 }, { w: 0.5, h: 1 }],
						"id": "leftColumnSplit"
					};
				}
				else {
					panels.push(newPanelItem);
				}
			}

			return newState;

		},
	},
	{}
);
