import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import API from '../../api/Api';
import Icon, { ICONS } from '../Icon/Icon';

import './PanelIcon.css';

export const PANEL_ICONS = {
	[API.panel.types.customerview]: ICONS.user,
	[API.panel.types.chat]: ICONS.chat,
	[API.panel.types.voice]: ICONS.phone,
	[API.panel.types.dialpad]: ICONS.dial,
	[API.panel.types.email]: ICONS.email,
	[API.panel.types.callLookup]: ICONS.phone,
	[API.panel.types.emailLookup]: ICONS.email,
	[API.panel.types.genericLookup]: ICONS.search,
	[API.panel.types.teamsCall]: ICONS.phone,
	[API.panel.types.scripting]: ICONS.robot,
	[API.panel.types.other]: ICONS.phone,
	[API.panel.types.closedCall]: ICONS.note,
	[API.panel.types.supervisor]: ICONS.chart,
	[API.panel.types.campaign]: ICONS.book
};

export const CHANNEL_PANEL_ICONS = {
	[API.conversationChannelTypes.facebook]: ICONS.facebook_colour,
	[API.conversationChannelTypes.sms]: ICONS.sms_colour,
	[API.conversationChannelTypes.twitter]: ICONS.twitter_colour,
	[API.conversationChannelTypes.webchat]: ICONS.webchat_colour,
	[API.conversationChannelTypes.whatsapp]: ICONS.whatsapp_colour,
	[API.conversationChannelTypes.directline]: ICONS.webchat_colour,
	[API.conversationChannelTypes["nexmo.whatsapp"]]: ICONS.whatsapp_colour,
	[API.conversationChannelTypes["nexmo.viber"]]: ICONS.viber_colour,
	[API.conversationChannelTypes.viber]: ICONS.viber_colour,
	[API.conversationChannelTypes.voice]: ICONS.phone,
	[API.conversationChannelTypes.email]: ICONS.email,
	[API.conversationChannelTypes.virtual]: ICONS.book,
	[API.conversationChannelTypes.instagram]: ICONS.instagram_colour,
	[API.conversationChannelTypes.infobipSms]: ICONS.sms_colour,
}

class PanelIcon extends PureComponent {
	render() {

		const { label, interactionType, direction, channelId } = this.props;
		const classes = classnames({
			'interaction-icon': true,
			[`interaction-icon--${direction}`]: direction
		});
		let mediaTypeId = null;
		switch (interactionType) {
			case API.panel.types.chat:
				mediaTypeId = `mediaTypeId_${interactionType}_${channelId}`
				break;
			case API.panel.types.voice:
				mediaTypeId = `mediaTypeId_${interactionType}`
				break;
			case API.panel.types.email:
				mediaTypeId = `mediaTypeId_${interactionType}`
				break;
			default:
				mediaTypeId = `mediaTypeId_unkownMediaType`
				break;
		}
		return (
			<span id={mediaTypeId} title={label} className={classes}>
				<Icon name={_.isNil(channelId) ? PANEL_ICONS[interactionType] : CHANNEL_PANEL_ICONS[channelId]} />
				<span className="direction-arrow direction-arrow--outbound">&#8599;</span>
				<span className="direction-arrow direction-arrow--inbound">&#8600;</span>
			</span>
		);
	}

	static propTypes = {
		label: PropTypes.string.isRequired,
		interactionType: PropTypes.oneOf(_.values(API.panel.types)).isRequired,
		direction: PropTypes.oneOf(_.flatten([_.values(API.panel.directions), false]))
	};

	static defaultProps = {
		direction: false
	};
}

export default PanelIcon;
