import _ from 'lodash';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { combineReducers } from 'redux';
import { calculateResponsiveState } from 'redux-responsive';

import * as Actions from '../../actions';
import { ICON_SPRITE } from '../../assets/sprite/icons';
import store from '../../services/createStore';

import { OidcProvider } from 'redux-oidc';
import Label from '../../components/Label/Label';
import Routes from '../../routes/Routes';
import * as Selectors from '../../selectors';
import userManager from '../../services/userManager';
import Overlay from '../Overlay/Overlay';
import ThemeManager from '../ThemeManager/ThemeManager';
import Waves from '../Waves/Waves';
import WebRtcProvider from '../WebRtc/WebRtcProvider';
import './App.css';
import LoadFragment from './LoadFragment';


/**
 * Main container rendered by index.js
 * 
 * Blocks default drag and drop behaviour
 * Reads cookies/query parameters and checks if every one of them are present
 * Creates the redux store
 * Dispatches a few initialization-related redux actions 
 */
class App extends Component {

	constructor(props) {
		super(props);

		this.errorMessage = null;
		this.chatMode = false;

		/*
		This part blocks drag and drop events from opening the files in the browser
		*/
		document.addEventListener('drop', function (e) {
			e.preventDefault();
			e.stopPropagation();
		});

		document.addEventListener('dragover', function (e) {
			e.preventDefault();
			e.stopPropagation();
		});


		const env = "development";
		// initialize store
		this.store = store;
		this.store.dispatch(Actions.setEnvironment(env));
		window.addEventListener('resize', _.debounce(() => this.store.dispatch(calculateResponsiveState(window)), 200));

		//Load UI fragments
		var fragmentRootUrl = Selectors.getFragmentUrl();

		const fragments = [
			{ fileName: "directory-listing-fragment.js", name: "Directory" },
			// replaced by v2
			// { fileName: "customer-data-fragment.js", name: "CustomerData" },
			// replaced by v2
			// { fileName: "activity-history-fragment.js", name: "ActivityHistory" },
			{ fileName: "callcontrol-fragment.js", name: "CallControl" },
			{ fileName: "chat-fragment-v2.js", name: "Chat" },
			{ fileName: "email-fragment.js", name: "Email" },
			// replaced by v2
			// { fileName: "virtual-conversation-fragment.js", name: "VirtualConversation" },
			{ fileName: "agent-performance-dashboard-fragment.js", name: "AgentPerformance" },

		];
		fragments.forEach(x => {
			LoadFragment(fragmentRootUrl + x.fileName, x.name + "Fragment");
		});
	}

	render() {

		// Enable hot reloading of store
		// https://github.com/reactjs/react-redux/releases/tag/v2.0.0
		if (process.env.NODE_ENV === 'development' && module.hot) {
			module.hot.accept('../../reducers', () => {
				const nextRootReducer = combineReducers(require('../../reducers/'));
				this.store.replaceReducer(nextRootReducer);
			});
		}

		return (
			<div>
				{this.errorMessage === null ?
					(
						<div className='app'>
							<div className='icon-sprite' dangerouslySetInnerHTML={{ __html: ICON_SPRITE }} />
							<OidcProvider store={this.store} userManager={userManager}>
								<Provider store={this.store}>
									<WebRtcProvider>
										<ThemeManager>
											<Waves />
											<Routes />
											<Overlay />
										</ThemeManager>
									</WebRtcProvider>
								</Provider>
							</OidcProvider>
						</div>
					) : (
						<div>
							<Label text={'¯\\_(ツ)_/¯'} />
							<Label text={this.errorMessage} />
						</div>
					)
				}
			</div>
		);
	}
}

export default App;