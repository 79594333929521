// Auto-generated file, created by 'npm run icon-sprite'. Do not modify!

const ICONS = {
	add: "add",
	addcall: "addcall",
	addcircle: "addcircle",
	book: "book",
	caselist: "caselist",
	change: "change",
	chart: "chart",
	chat: "chat",
	chatbubbleleft: "chatbubbleleft",
	chatbubbleright: "chatbubbleright",
	clock: "clock",
	close: "close",
	collapse: "collapse",
	cross: "cross",
	dial: "dial",
	downarrow: "downarrow",
	edit: "edit",
	email: "email",
	extract: "extract",
	facebook_colour: "facebook_colour",
	facebook_greyscale: "facebook_greyscale",
	fileattachment: "fileattachment",
	folder: "folder",
	hangup: "hangup",
	instagram_colour: "instagram_colour",
	instagram_greyscale: "instagram_greyscale",
	layout: "layout",
	layout_bottomrowsplit: "layout_bottomrowsplit",
	layout_crosssplit: "layout_crosssplit",
	layout_fullsize: "layout_fullsize",
	layout_leftcolumnsplit: "layout_leftcolumnsplit",
	layout_rightcolumnsplit: "layout_rightcolumnsplit",
	layout_toprowsplit: "layout_toprowsplit",
	layout_twocolumns: "layout_twocolumns",
	layout_tworows: "layout_tworows",
	leftarrow: "leftarrow",
	menu: "menu",
	mic: "mic",
	micmute: "micmute",
	note: "note",
	paperclip: "paperclip",
	pause: "pause",
	phone: "phone",
	play: "play",
	rightarrow: "rightarrow",
	robot: "robot",
	search: "search",
	settings: "settings",
	sms_colour: "sms_colour",
	sms_greyscale: "sms_greyscale",
	submenu: "submenu",
	tasklist: "tasklist",
	tick: "tick",
	transfercall: "transfercall",
	twitter_colour: "twitter_colour",
	twitter_greyscale: "twitter_greyscale",
	user: "user",
	userchange: "userchange",
	viber_colour: "viber_colour",
	videocall: "videocall",
	webchat_colour: "webchat_colour",
	webchat_greyscale: "webchat_greyscale",
	whatsapp_colour: "whatsapp_colour",
	whatsapp_greyscale: "whatsapp_greyscale"
};

export default ICONS;
export { ICONS };
