import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as Selectors from '../../selectors';
import * as Actions from '../../actions';
import { bindActionCreators } from 'redux';
/**
 * Encapsulates the chat fragment
 */
class ChatPanel extends Component {
	
	constructor(props) {
        super();
        this.fragmentRef = React.createRef();
	}
	
    componentDidMount() {		
        this.fragmentRef.current.addEventListener('onUnreadMessageCountChanged', ev => {
			var count = ev.detail.count;
			this.props.unreadMessageCountChanged(this.props.conversationId, count);
		  });
	}
	
	render() {
		const { chatHubUrl, accessToken, conversationId, contentMgrUrl, panelId } = this.props;
		return (
			<chat-fragment
				ref={this.fragmentRef} 
				id={panelId}
				chathuburl= {chatHubUrl}
				token={accessToken}
				conversationid={conversationId}
				contentapiurl= {contentMgrUrl}
				style={{ "height": "calc(98% - 30px)" }}>
			</chat-fragment>
		);
	}

	static propTypes = {
		conversationId: PropTypes.string.isRequired,
		panelId: PropTypes.string.isRequired,
	};
}

const mapStateToProps = (state, ownProps) => {
	const panelId = ownProps.panelId;
	const conversationId = Selectors.getDataForChatPanel(state, panelId);
	const chatHubUrl = Selectors.getAksApiUrl(state) + "/chat/hub"
	const accessToken = Selectors.backEndAccessToken(state);
	const contentMgrUrl = Selectors.getDocumentStoreUrl(state);
	return {
		chatHubUrl,
		accessToken,
		contentMgrUrl,		
		conversationId
	}

};

const mapDispatchToProps = (dispatch) => {
	return {
		unreadMessageCountChanged: bindActionCreators(Actions.unreadMessageCountChanged, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatPanel);
