import { defineMessages } from 'react-intl';

const messages = defineMessages({
	SidebarComposed_Request: {
		defaultMessage: 'Request',
		id: 'SidebarComposed_Request',
		description: 'Sidebar / request menuitem title'
	},

	SidebarComposed_Logout: {
		defaultMessage: 'Logout',
		id: 'SidebarComposed_Logout',
		description: 'Sidebar / logout request menuitem title'
	},

	SidebarComposed_Incoming: {
		defaultMessage: 'Incoming interaction',
		id: 'SidebarComposed_Incoming',
		description: 'Sidebar / incoming menuitem title'
	},

	SidebarComposed_CQManager: {
		defaultMessage: 'Live Queue',
		id: 'SidebarComposed_cqManager',
		description: 'Sidebar / Cherry-picking title'
	},

	SidebarComposed_IncomingWaiting: {
		defaultMessage: 'waiting...',
		id: 'SidebarComposed_IncomingWaiting',
		description: 'Sidebar / incoming menuitem subtitle'
	},

	SidebarComposed_Search: {
		defaultMessage: 'Search tasks',
		id: 'SidebarComposed_search',
		description: 'Sidebar / search menuitem title'
	},

	SidebarComposed_PendingBreakSubtitle: {
		defaultMessage: 'Pending Break',
		id: 'SidebarComposed_pendingBreakSubtitle',
		description: 'Sidebar / pending break panelset subtitle'
	},

	SidebarComposed_AddTask: {
		defaultMessage: 'Add task',
		id: 'SidebarComposed_AddTask',
		description: 'Add task / search menuitem title'
	},

	SidebarComposed_NewVoiceCall: {
		defaultMessage: 'New outbound call',
		id: 'SidebarComposed_NewVoiceCall',
		description: 'New outbound (teams) voice call'
	},

	SidebarComposed_NewOutboundEmail: {
		defaultMessage: 'New outbound email',
		id: 'SidebarComposed_NewOutboundEmail',
		description: 'New outbound email'
	},

	SidebarComposed_NewOutbound: {
		defaultMessage: 'New outbound conversation',
		id: 'SidebarComposed_NewOutbound',
		description: 'New outbound conversation'
	},

	SidebarComposed_UserSettings: {
		defaultMessage: 'User settings',
		id: 'SidebarComposed_UserSettings',
		description: 'User settings menuitem title'
	},

	SidebarComposed_CallLookup: {
		defaultMessage: 'New Call',
		id: 'SidebarComposed_CallLookup',
		description: 'New Call menuitem title'
	},

	SidebarComposed_EmailLookup: {
		defaultMessage: 'New email',
		id: 'SidebarComposed_EmailLookup',
		description: 'New email menuitem title'
	},

	SidebarComposed_GenericLookup: {
		defaultMessage: 'Contact lookup',
		id: 'SidebarComposed_GenericLookup',
		description: 'Generic lookup menuitem title'
	},

	SidebarComposed_Supervisor: {
		defaultMessage: 'Supervisor Dashboard',
		id: 'SidebarComposed_Supervisor',
		description: 'Dashboard for supervisor view'
	},
});

export default messages;