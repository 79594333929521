import ContactLookupFragment from '@buzzeasy/contact-lookup-fragment';
import { ScriptRenderer } from '@buzzeasy/script-renderer';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Selectors from '../../selectors';

class GenericLookupPanel extends Component {

    render() {
        const { token, aksApiUrl, scriptProcessId, workItemId, hideSensitiveAgentInformation } = this.props;
        return (<div style={{ "height": "calc(100% - 30px)" }}>
            {scriptProcessId
                ? <ScriptRenderer
                    token={token}
                    apiBaseUrl={aksApiUrl}
                    scriptProcessId={scriptProcessId}
                    theme={{ token: { fontSize: 16 } }}
                />
                : <ContactLookupFragment
                    token={token}
                    apiBaseUrl={aksApiUrl}
                    workItemId={workItemId}
                    featureFlags={{ hideSensitiveAgentInformation }}
                />
            }
        </div>);
    }

    static propTypes = {
        token: PropTypes.string.isRequired,
        aksApiUrl: PropTypes.string.isRequired,
        scriptProcessId: PropTypes.string,
        workItemId: PropTypes.string.isRequired,
        hideSensitiveAgentInformation: PropTypes.bool.isRequired,
    };
}

const mapStateToProps = (state, ownProps) => {
    const { panelId, panelSetId, workItem } = ownProps;

    const scriptProcessId = state.backEnd.stateData.workItems[panelSetId].scriptProcessId;
    const token = Selectors.backEndAccessToken(state);
    const aksApiUrl = Selectors.getAksApiUrl(state);
    const hideSensitiveAgentInformation = Selectors.getDisplaySettings(state)?.hideSensitiveAgentInformation ?? false;

    return {
        token,
        aksApiUrl,
        workItemId: workItem.workItemId,
        scriptProcessId,
        hideSensitiveAgentInformation,
    };
};

export default connect(mapStateToProps)(GenericLookupPanel);
