import { ScriptRenderer } from '@buzzeasy/script-renderer';
import * as Mustache from 'mustache';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReduxModels from '../../api/ReduxModels';
import Label from '../../components/Label/Label';
import * as Selectors from '../../selectors';

function resolveUrlTemplate(urlTemplate, workItem, customer, token, profile) {

    const mustacheView = {
        'Agent': {
            'Id': profile.sub,
            'Name': profile.name,
            'Token': token,
        },
        'CustomerIdentifier': workItem.customerIdentifier,
        'WorkItemId': workItem.workItemId,
        'ConversationId': workItem.primaryConversationId,
        'Customer': {
            'ExternalId': customer?.externalId,
        }
    }

    try {
        return Mustache.render(urlTemplate, mustacheView);
    } catch (error) {
        console.error(error);
        return null;
    }
}

class ScriptingPanel extends Component {

    render() {
        const { url, loading, panelId, token, aksApiUrl, customer, workItem, profile, scriptProcessId, externalWebPageUrlTemplate } = this.props;

        // In-house scripting
        if (scriptProcessId) {
            return (
                <div style={{ "height": "calc(100% - 30px)" }}>
                    <ScriptRenderer token={token} apiBaseUrl={aksApiUrl} scriptProcessId={scriptProcessId} theme={{ token: { fontSize: 16 } }} />
                </div>
            )
        }

        // External website
        if (externalWebPageUrlTemplate) {
            if (workItem.customerId && !customer) {
                return <Label text="Loading..." />
            }
            const resolvedUrl = resolveUrlTemplate(externalWebPageUrlTemplate, workItem, customer, token, profile);
            return resolvedUrl
                ? <iframe title={'extSite_' + workItem.workItemId} src={resolvedUrl} style={{ "height": "calc(100% - 30px)" }} />
                : <Label text="Failed to resolve url template. Please contact your supervisor." />;
        }

        // Fallback to legacy "scripting"
        if (loading) {
            return (<Label text="Loading..." />)
        }
        else {
            if (url) {
                return (<iframe title={panelId} src={url} style={{ "height": "calc(100% - 30px)" }} frameBorder="0" />)
            }
            else {
                return (<Label text="Loading..." />)
            }
        }
    }

    static propTypes = {
        panelId: PropTypes.string.isRequired,
        scriptProcessId: PropTypes.string,
        externalWebPageUrlTemplate: PropTypes.string,
        aksApiUrl: PropTypes.string.isRequired,
        token: PropTypes.string.isRequired,
        profile: PropTypes.object.isRequired,
        workItem: PropTypes.object.isRequired,
    };
}

const mapStateToProps = (state, ownProps) => {

    const { panelId, workItem } = ownProps;
    const scriptingReduxObject = Selectors.getDataForScriptingPanel(state, panelId);
    const token = Selectors.backEndAccessToken(state);
    const aksApiUrl = Selectors.getAksApiUrl(state);
    const customer = Selectors.getCustomer(state, workItem.workItemId);

    const { scriptProcessId, externalWebPageUrlTemplate } = workItem;

    const { profile } = Selectors.getOidcUser(state);

    return {
        loading: scriptingReduxObject ? scriptingReduxObject[ReduxModels.scripting.loading] : false,
        url: scriptingReduxObject ? scriptingReduxObject[ReduxModels.scripting.url] : null,
        token,
        profile,
        workItem,
        customer,
        aksApiUrl,
        scriptProcessId,
        externalWebPageUrlTemplate,
    };
};

export default connect(mapStateToProps)(ScriptingPanel);
