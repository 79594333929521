// https://gist.github.com/James1x0/8443042

export const TIME_OF_DAY = {
	afternoon: 'afternoon',
	evening: 'evening',
	morning: 'morning'
};

export const getTimeOfDay = function (m) {
	let g = null; //return g

	if (!m || !m.isValid()) { return; } //if we can't find a valid or filled moment, we return.

	const split_afternoon = 12; //24hr time to split the afternoon
	const split_evening = 17; //24hr time to split the evening
	const currentHour = parseFloat(m.format("HH"));

	if (currentHour >= split_afternoon && currentHour <= split_evening) {
		g = TIME_OF_DAY.afternoon;
	} else if (currentHour >= split_evening) {
		g = TIME_OF_DAY.evening;
	} else {
		g = TIME_OF_DAY.morning;
	}

	return g;
};

/**
 * Return the elapsed time in seconds between the two specified datetime
 * @param {Date} earlierDateTime The earlier date
 * @param {Date} laterDateTime The later date
 */
export const getElapsedTimeInSeconds = function (earlierDateTime, laterDateTime) {
	const earlierDateTime_ms = earlierDateTime.getTime();
	const laterDateTime_ms = laterDateTime.getTime();

	const difference_ms = laterDateTime_ms > earlierDateTime_ms ? laterDateTime_ms - earlierDateTime_ms : 0;
	return difference_ms / 1000;
};

/**
 * Add extra zero characters to the begginig of a string
 * @param {string} item String you want to pad with zero characters
 * @param {number} requiredLength Length of the required string
 */
const padZero = function (item, requiredLength) {

	var result = "";

	if (requiredLength) {

		result = item.toString();

		while (result.length < requiredLength) {

			result = "0" + result;
		}
	}

	return result;
};

/**
 * Parse seconds to duration string
 * @param {number} totalSeconds Seconds to parse 
 * @param {boolean} showDays Flag to show elapsed days or add that to hours
 * @param {string} dayWord Localized 'day' word
 * @param {string} daysWord Localized 'days' word
 */
export const parseSeconds = function (totalSeconds, showDays, dayWord, daysWord) {

	if (!totalSeconds) {

		return "00:00";
	}

	var result = "";
	var hours = Math.floor(totalSeconds / 3600);
	var minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
	var seconds = Math.floor(totalSeconds - (hours * 3600) - (minutes * 60));

	if (showDays) {
		var days = Math.floor(hours / 24);
		if (days > 0) {
			hours = (hours - (24 * days));
			var dayString = (days > 1 ? daysWord : dayWord).toLowerCase();
			result += days + " " + dayString + " ";
		}
	}

	result += (hours > 0 ? padZero(hours, 2) + ":" : "") + padZero(minutes, 2) + ":" + padZero(seconds, 2);

	return result;
};

export function formatNum(num) {
	return `${num >= 10 ? '' : '0'}${num}`;
}

const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

export function breakDownSeconds(seconds) {
	const result = { days: 0, hours: 0, minutes: 0, seconds: 0 };
	let remainingSeconds = seconds;

	if (remainingSeconds >= DAY) {
		result.days = Math.floor(remainingSeconds / DAY);
		remainingSeconds -= result.days * DAY;
	}

	if (remainingSeconds >= HOUR) {
		result.hours = Math.floor(remainingSeconds / HOUR);
		remainingSeconds -= result.hours * HOUR;
	}

	if (remainingSeconds >= MINUTE) {
		result.minutes = Math.floor(remainingSeconds / MINUTE);
		remainingSeconds -= result.minutes * MINUTE;
	}

	result.seconds = Math.floor(remainingSeconds);
	return result;
}

export function formatTimeSpan(timeSeconds) {
	const { days, hours, minutes, seconds } = breakDownSeconds(Math.abs(timeSeconds));
	let value = '';

	if (days > 0) {
		value = `${days}d ${hours}h`;
	}
	else {
		if (hours > 0)
			value = `${hours}:${formatNum(minutes)}:${formatNum(seconds)}`;
		else
			value = `${minutes}:${formatNum(seconds)}`;
	}

	return timeSeconds < 0
		? `${value} remaining`
		: value;
}