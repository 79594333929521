import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import API from '../../api/Api';

import { connect } from 'react-redux';
import Label from '../../components/Label/Label';
import { getUserStateSince, getWorkItemById } from '../../selectors';
import CallPanel from '../CallPanel/CallPanel';
import ChatPanel from '../ChatPanel/ChatPanel';
import CustomerPanel from '../CustomerPanel/CustomerPanel';
import EmailPanel from '../EmailPanel/EmailPanel';
import GenericLookupPanel from '../GenericLookupPanel/GenericLookupPanel';
import IdlePanelContainer from '../IdlePanelContainer/IdlePanelContainer';
import Request from '../Request/Request';
import ScriptingPanel from '../ScriptingPanel/ScriptingPanel';
import StartShift from '../StartShift/StartShift';
import SupervisorPanel from '../SupervisorPanel/SupervisorPanel';
import VirtualConversationPanel from '../VirtualConversationPanel/VirtualConversationPanel';

/**
 * When you need to render out a panel, you generate this and this decides what class type to instantiate based on the properties (like interactionType.
 */
class Panel extends PureComponent {
	render() {
		const { panelType, panelId, agentStateSince, panelSetId, workItem } = this.props;

		let panelComponent;

		switch (panelType) {

			case API.panel.types.chat:
				panelComponent = (<ChatPanel panelId={panelId} />);
				break;

			case API.panel.types.incoming:
				panelComponent = (<IdlePanelContainer agentStateSince={agentStateSince} />);
				break;

			case API.panel.types.startShift:
				panelComponent = (<StartShift />);
				break;

			case API.panel.types.break:
				panelComponent = (<Request />);
				break;

			case API.panel.types.pendingLogout:
				panelComponent = (<Request />);
				break;

			case API.panel.types.pendingBreak:
				panelComponent = (<Request />);
				break;

			case API.panel.types.loadingScreen:
				panelComponent = (<Label text={"Panel is loading..."} />)
				break;

			case API.panel.types.customerview:
				panelComponent = (<CustomerPanel panelId={panelId} workItem={workItem} />)
				break;

			case API.panel.types.email:
				panelComponent = (<EmailPanel panelId={panelId} />)
				break;

			case API.panel.types.campaign:
				panelComponent = (<VirtualConversationPanel panelId={panelId} />)
				break;

			case API.panel.types.callLookup:
				panelComponent = (<CallPanel panelId={panelId} workItem={workItem} />)
				break;

			case API.panel.types.emailLookup:
				panelComponent = (<EmailPanel panelId={panelId} />)
				break;

			case API.panel.types.genericLookup:
				panelComponent = (<GenericLookupPanel panelId={panelId} panelSetId={panelSetId} workItem={workItem} />)
				break;

			case API.panel.types.teamsCall:
				panelComponent = (<CallPanel panelId={panelId} workItem={workItem} />)
				break;

			case API.panel.types.closedCall:
				panelComponent = (<Label text={"Conversation Has Ended"} />)
				break;

			case API.panel.types.scripting:
				panelComponent = (<ScriptingPanel panelId={panelId} panelSetId={panelSetId} workItem={workItem} />)
				break;
			case API.panel.types.supervisor:
				panelComponent = (<SupervisorPanel />)
				break;
			default:
				panelComponent = (<Label text={"Undefined panelType requested"} />)
				break;
		}

		return panelComponent;
	}

	static propTypes = {
		panelId: PropTypes.string,
		agentStateSince: PropTypes.number,
		workItem: PropTypes.object,
	};
}

const mapStateToProps = (state, ownProps) => {
	const panelSetId = ownProps.panelSetId;
	const panelId = ownProps.panelId;
	const panel = state[API.panel._key][panelId];
	const panelType = panel[API.panel.type];
	const agentStateSince = getUserStateSince(state);

	const workItem = getWorkItemById(state, panelSetId);

	return {
		workItem,
		panelType,
		agentStateSince
	}
}

export default connect(mapStateToProps)(Panel);