const API = {
  capability: {
    _key: 'capability',
    title: 'title',
    href: 'href',
    method: 'method',
    methods: {
      post: 'POST',
      del: 'DELETE',
      patch: 'PATCH',
      put: 'PUT',
      get: 'GET',
      websocket: 'WEBSOCKET'
    },
    requiredParameters: 'requiredParameters'
  },
  /**
   * Set by receiving cookies in the beginning.
   */
  envTypes: {
    development: 'development',
    qa: 'qa',
    production: 'production'
  },

  //baseUrl: 'http://localhost:3001/',

  /**
   * UI element which might contain 0 or more panels.
   * In terms of the UI hierarchy, 'panelSet' is somewhere 
   * between the 'stage' and the 'panel'.
   */
  panelSet: {
    _key: 'panelSet',
    /**
     * Do not confuse this with workItemId.
     */
    id: 'id',
    /**
     * Ids for a few "hard-coded" panelsets, which only contain one type of panel, like the panelSet containing the idlePanel.
     * This makes it easy to create/delete them.
     */
    ids: {
      breakPanelSet: "breakPanelSet",
      idlePanelSet: "idlePanelSet",
      dashboard: "dashboard",
      pendingLogoutPanelSet: "pendingLogoutPanelSet",
      pendingBreakPanelSet: "pendingBreakPanelSet",
      callLookup: "callLookup",
      emailLookup: "emailLookup",
      genericLookup: 'genericLookup',
      supervisor: "supervisor"
    },
    /**
     * Set the value as {} when creating a panelSet, the various actions will then work on this.
     */
    layout: 'layout',

    status: 'status',
    /**
     * Number from 1 to 8.
     */
    color: 'color',
    /**
     * This contains the panelGroups themselves in an array, not just their IDs.
     * This should be renamed to 'panelGroups'
     */
    panels: 'panels',
    /**
     * This is not saved in the Redux store. Instead, it is calculated whenever needed. 
     * This is true when the paneSet is the currentPanelSet in the user object.
     */
    active: 'active',

    timing: 'timing',
    /**
     * PanelSet status enum.
     * Important to decide how to render this panelSet. This is similar to panel.type.
     * Currently this only decides wether header should be rendered or not, and what the title of that header should be
     */
    statuses: {
      incoming: 'incoming',
      search: 'search',
      /**
       * Used by chat interactions.
       */
      inprogress: 'inprogress',

      closed: 'closed',
      /**
       * dashboard contains the startshift panel.
       */
      dashboard: 'dashboard',
      /**
       * Could contain PendingLogout and PendigBreak panels or similar.
       */
      request: 'request',

      callLookup: 'callLookup',

      emailLookup: 'emailLookup',

      genericLookup: 'genericLookup',

      supervisor: 'supervisor'
    },
    /**
     * This is null when the panelSet is not connected to a workItem when it is not Null, 
     * then if this Id is not in the capability, then the panelSet gets deleted.
     */
    workItemIdV2: 'workItemId',

    workItemId: 'workItemId',
    /**
     * This is not stored in the redux Store. This property's value is set by selectors when required.
     */
    title: 'title',
    /**
     * Only relevant when the panelSet is connected to a workItem that has a chatInteraction 
     * this is not part of the redux store, instead calculated every time it is required.
     */
    unreadMessagesCount: 'unreadMessagesCount',

    //temporary flag indicating that while based on the status the panelSet should have a taskManager arrow, we disable it still due to incomplete feature
    taskManagerArrowDisabled: 'taskManagerArrowDisabled',

    // if this panelSet belongs to a conversation, this is it's Id.
    conversationId: 'conversationId',

    // if this panelSet belongs to a conversation, this is the customerId
    customerId: 'customerId',

    //createdAtTime
    createdAt: 'createdAt'

  },

  /**
   * The highest level element in the UI hierarchy. There can be multiple stages on the UI at the same time. 
   * Each stage can contain 0 or multiple panel sets.
   */
  stage: {
    // NOTE: will be renamed to 'stage'
    _key: 'stages',
    /**
     * Id of the stage UI element.
     */
    id: 'id',
    /**
     * Some hardcoded stage ids to make initialization of singleton stages easier.
     */
    ids: {
      defaultStage: 'defaultStage',
      userSettingsStage: 'userSettingsStage',
      callLookup: 'callLookup',
      emailLookup: 'emailLookup',
      genericLookup: 'genericLookup',
      supervisor: 'supervisor'
    },
    /**
     * Set this to {} when creating a stage.
     */
    layout: 'layout',
    /**
     * This is an array of PanelSetIds in the store, but sometimes it is actually mapped out and contains an array of PanelSets.
     * NOTE: will be renamed to 'panelSets'
     */
    panelSets: 'panelSet',
    /**
     * Used when creating the dashboard at init.
     */
    defaultStage: 'defaultStage',
    /**
     * This is not saved in the Redux store. Instead, it is calculated whenever needed. 
     * This is true when the stage is the currentStage in the user object.
     */
    active: 'active'
  },

  panelGroup: {
    _key: 'panelGroup',
    id: 'id',

    /**
     * Array of Ids
     */
    panels: 'panels',

    /**
     * Describes which panel is active (value is a panelId)
     */
    active: 'active'
  },

  /**
   * The lowest level element in the UI hierarchy. It is located inside a panel set.
   */
  panel: {
    /**
     * Key in the store.
     */
    _key: 'panel',
    /**
     * Id of the panel UI element. Not the same as the interactionId!
     */
    id: 'id',

    ids: {
      callLookup: 'callLookupId',
      emailLookup: 'emailLookup',
      genericLookup: 'genericLookup',
      supervisor: 'supervisor'
    },
    /**
     * Set this to {} when creating a new panel.
     */
    layout: 'layout',
    /**
     * Previously contained various infos about the interaction, depending on the type of it. 
     * Currently unused, soon to be deleted.
     */
    content: 'content',
    /**
     * Important when deciding how to render the panel, with which React component .
     */
    type: 'type',
    /**
     * Flag to indicate if this panel is the active one.
     */
    active: 'active',
    /**
     * ?
     */
    panels: 'panels',
    /**
     * Soon to be deleted.
     */
    direction: 'direction',
    /**
     * Direction enum.
     */
    directions: {
      inbound: 'inbound',
      outbound: 'outbound'
    },
    /**
     * Type enum.
     * These are also used to specify Ids to those panels, which only should be created once (like incoming panel).
     */
    types: {
      /**
       * Panel type for chat interaction.
       */
      chat: 'chat',

      /**
       * when we don't know what to render, render this.
       */
      loadingScreen: 'loadingScreen',
      /**
       * This creates the "Waiting for interaction... panel".
       */
      incoming: 'incoming',
      /**
       * Similar to a breakpanel but this also has a welcome message.
       */
      startShift: 'startShift',
      /**
       * Panel type for break panel.
       */
      break: 'break',

      /**
       * Panel type to display when the user is in pending logout state.
       */
      pendingLogout: 'pendingLogout',
      /**
       * Panel type to display when the user is in pending break state.
       */
      pendingBreak: 'pendingBreak',
      /**
       * Panel type to display when the user initiates a new work item.
       */
      emptyWorkItem: 'emptyWorkItem',

      /**
       * Reading and managing emails within an interaction
       */
      email: 'email',

      campaign: 'campaign',

      callLookup: 'callLookup',

      emailLookup: 'emailLookup',

      genericLookup: 'genericLookup',

      teamsCall: 'teamsCall',

      customerview: 'customerview',

      closedCall: 'closedCall',

      scripting: 'scripting',

      supervisor: 'supervisor',

      other: 'other'
    },

    /**
     * Used when the panel is directly related to a workItem (for example when the WI has no interactions we create a panel for work Item management)
     * Undefined in any other scenario
     */

    workItemId: 'workItemId',

    /**
     * Used when the panel is DIRECTLY related to a conversation.
     * This is not used when the panel is related to any interaction or workItem.
     */
    conversationId: 'conversationId',

    /**
     * Defines which channel the chat interaction is coming from
     * undefined in other scenarios
     */
    channelId: 'channelId'
  },

  /**
   * Data structure holding different information about the local user (aka. agent).
   */
  user: {
    _key: 'user',
    id: 'id',
    tenant: 'tenant',
    accessToken: 'accessToken',
    name: 'name',
    photo: 'photo',
    /**
     * This is an ARRAY of actual stages.
     */
    stages: 'stages',
    /**
     * Defines which locale.json to load.
     */
    language: 'language',
    /**
     * Contains the locale.json which is generated with React intl from the various <component>.messages.js file.
     */
    translations: 'translations',
    theme: 'theme',
    /**
     * Stage in focus.
     * Only the current stage is rendered and the currentPanelSet helps with switching between them via the TaskManager.
     */
    currentStage: 'currentStage',
    /**
     * PanelSet in focus.
     * Only the current stage is rendered and the currentPanelSet helps with switching between them via the TaskManager.
     */
    currentPanelSet: 'currentPanelSet',
    /**
     * Panel in focus.
     */
    currentPanel: 'currentPanel',
    /**
     * Content in focus.
     */
    currentContent: 'currentContent',
    /**
     * Used by the taskmanager.
     */
    taskManagerPanelSet: 'taskManagerPanelSet',
    /**
     * Used by the taskmanager.
     */
    isTaskManagerOpen: 'isTaskManagerOpen',
    /**
     * Name of the agent.
     */
    name: "name",
    /**
     * Returns true if the agent is supervisor.
     */
    isSupervisor: "isSupervisor",
    /**
     * Unknown usage, soon to be deleted.
     */
    incomingInteraction: 'incomingInteraction',
    /**
     * Unknown usage, soon to be deleted.
     */
    waitInteractions: 'waitInteractions',
    /**
     * Represents that the logout was requested. 
     * Only used to disable the auto-login feature when the user wants to log out.
     * This is normally false and only switched to true when a logoutrequest action is raised.
     * Useful when deciding to either try to re-login to the backend, or redirect to the LoginPage.
     * This is immeditaly set to true when a logout is requested, regardless of when/what the backEnd responds.
     */
    logoutRequested: 'logoutRequested',

    /**
     * User related settings
     */
    settings: {
      _key: 'settings',
      extension: 'extension',
      agentId: 'agentId',
      password: 'password',
      voiceEnabled: 'voiceEnabled'
    }
  },

  /**
   * Data structure holding information about the ongoing UI effects.
   */
  uiState: {
    _key: 'uiState',
    /**
     * False before loadInitialElements is executed, true afterwards. 
     * Important because other actions depend on the objects generated during init.
     * Should be replaced by defining a proper default redux store state.
     */
    initialDataLoaded: 'initialDataLoaded',
    uiProcess: 'uiProcess',
    wavePhase: 'wavePhase',
    translations: 'translations',
    overlay: 'overlay',
    overlayTypes: {
      incomingInteraction: 'incomingInteraction'
    },
    /**
     * Used to allocate colors (numbers between 1 and 8) to panelSets.
     */
    unusedPanelSetColors: 'unusedPanelSetColors',
    loadSuperVisorBreakCard: "loadSuperVisorBreakCard"
  },

  login: {
    _key: 'login',
    phase: 'phase'
  },

  /**
   * Data structure holding information about the current UI state (e.g. currently selected panel).
   */
  currentStatusInfo: {
    panelSetId: 'panelSetId',
    panelId: 'panelId',
    content: 'content',
    startTime: 'startTime',
    wait: 'wait'
  },

  /**
   * Data structure holding information about the back-end connection and the retrieved raw state data.
   */
  backEnd: {
    _key: 'backEnd',
    stateUrl: 'stateUrl',
    loginUrl: 'loginUrl',
    stateData: 'stateData',
    //reference to the redux store
    store: 'store',
    lastStateTimestamp: 'lastStateTimestamp',
    instrumentationKey: 'instrumentationKey',
    sipConnectorUrl: 'sipConnectorUrl',
    /**
     * Number of attempts where retrieving the state failed. 
     * Number starting from 0.
     */
    failedAttempt: 'failedAttempt'
  },

  /**
   * Data structure holding information about the structure of the response the backend gives.
   * Root component of the response.
   */
  capabilityResponse: {
    _key: 'capabilityResponse',
    agentProperties: 'agentProperties',
    /**
     * Array.
     */
    workItems: 'workItems',
    messages: 'messages',
    //this will be renamed to simply workItems in the next version
    workItemsV2: 'workItems',
    timeStamp: 'timeStamp'
  },

  conversationChannelTypes: {
    voice: "voice",
    facebook: "facebook",
    sms: "sms",
    twitter: "twitter",
    webchat: "webchat",
    whatsapp: "whatsapp",
    directline: "directline",
    "nexmo.whatsapp": "nexmo.whatsapp",
    "nexmo.viber": "nexmo.viber",
    email: "email",
    virtual: "virtual",
    viber: "viber",
    instagram: "instagram",
    infobipSms: "infobip_sms",
  },

  workItemV2: {
    _key: 'workItemV2',
    customerId: "customerId",
    workItemId: "workItemId",
    workItemState: "workItemState",
    workItemStates: {
      connected: "Connected",
      closed: "Closed",
      afterwork: "Afterwork",
      preview: "Preview",
      lookUp: "LookUp"
    },
    conversations: "conversations",
    channelId: "channelId",
    createTime: "createTime",
    lastStateChangeDate: "lastStateChangeDate",
    primaryConversationId: "primaryConversationId",
    usableClosureCodes: "usableClosureCodes", //list,
    createdAt: "createdAt",
    primaryMediaType: "primaryMediaType",
    primaryMediaTypes: {
      voice: "Voice",
      email: "Email",
      webchat: "Webchat",
      messaging: "Messaging"
    }
  },

  conversation: {
    channelType: "channelType",
    conversationId: "conversationId"
  },

  agentProperties: {
    _key: 'agentProperties',
    id: 'id',
    state: 'state',
    breakCode: 'breakCode',
    breakName: 'breakName',
    stateSince: 'stateSince',
    capabilities: 'capabilities',
    capabilityTypes: {
      voice: "Voice",
      email: "Email"
    }
  },

  agentStatusTypes: {
    idle: 'Idle',
    pendingLogout: 'PendingLogout',
    loggedOut: 'LoggedOut',
    pendingBreak: 'PendingBreak',
    break: 'Break',
    busy: 'Busy'
  },

  resourceStateChanges: {
    leaveBreak: 'leaveBreak',
    logIn: 'logIn',
    logOut: 'logOut',
    goToBreak: 'goToBreak',
  },
  /**
   * This contains the chatConnectionWrapper classes.
   * InteractionId - ChatConnectionWrapper dictionary.
   */
  chat: 'chat',

  messages: {
    _key: 'messages',
  },

  message: {
    _key: 'message',
    id: 'id',
    timestamp: 'timestamp',
    text: 'text',
    link: 'link',
  },

  // The break names the agent can select one from
  // when trying to change to Break state.
  // NOTE: Break names are stored as a simple JavaScript 
  // array in the Redux store under this key.
  breakNames: {
    _key: 'breakNames'
  },


  //redux model used by the 'notifications' feature
  //a.k.a. changing window title due to incoming message or new interaction
  notifications: {
    _key: "notifications",

    //whether the tab is in focus or not
    isFocused: "isFocused",

    //last time the window was in focus
    lastFocusDate: "lastFocusDate"
  },

  oidc: {
    _key: "oidc",
    user: {
      _key: "user",
      accessToken: "access_token",
      profile: {
        _key: "profile",
        name: "name"
      }
    }
  }
};

export default API;