import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import API from '../../api/Api';
import ICONS from '../../data/icons';

import messages from './PanelSetPanelTabs.messages';

import * as ACTIONS from '../../actions';

import PanelTab from '../../components/PanelTab/PanelTab';
import PanelTabsSubmenu from '../../components/PanelTabsSubmenu/PanelTabsSubmenu';
import './PanelSetPanelTabs.css';

/**
 * This react component sits between the PanelSet and the Panel in the react tree, gives the Panel a framing with a Title, etc.
 */
class PanelSetPanelTabs extends Component {

	constructor(props) {
		super(props);

		this.needsRearrange = true;
		this.tabs = {};
		this.state = {
			hiddenTabs: []
		};
	}

	arrangeTabs = () => {
		const hiddenTabs = [];

		this.tabs = _.pickBy(this.tabs, (tab) => !_.isNull(tab));

		if (_.size(this.tabs)) {
			_.forOwn(this.tabs, (tab, key) => {
				if (tab.offset() > 0) {
					hiddenTabs.push(key);
				}
			});
		}

		this.setState(() => ({
			hiddenTabs: hiddenTabs
		}));
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (!_.isEqual(nextProps.panelGroup[API.panel.panels], this.props.panelGroup[API.panel.panels])) {
			this.tabs = {};
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.panelGroup[API.panel.panels], this.props.panelGroup[API.panel.panels]) || (prevProps.width !== this.props.width)) {
			this.arrangeTabs();
		}
	}

	render() {

		const { intl, panelSetId, panelSetColor, panelGroup, panels, activeType, onActivate, onCloseTab, debugModeEnabled, onCloseCallLookup } = this.props;
		const { hiddenTabs } = this.state;
		const { formatMessage } = intl;

		//Todo: Rename interactionGroup -> panelGroup interaction -> panel
		const panelGroupId = panelGroup[API.panel.id];
		const activeId = panelGroup[API.panel.active];
		const panelGroupPanels = panelGroup[API.panel.panels];

		const hiddenTabItems = [];
		const allTabs = _.map(panelGroupPanels, (panelId) => {

			const panel = panels[panelId];
			const panelType = panel[API.panel.type];
			const label = formatMessage(messages['InteractionTab_' + panelType]);

			const closeEnabled = ([API.panel.types.userSettings, API.panel.types.customerview, API.panel.types.scripting].includes(panelType));

			const onCloseCallback = () => {
				switch (panelType) {
					case API.panel.types.customerview:
						onCloseTab(panelSetId, panelGroupId, panelId);
						break;
					case API.panel.types.scripting:
						onCloseTab(panelSetId, panelGroupId, panelId);
						break;
					case API.panel.types.callLookup:
					case API.panel.types.emailLookup:
					case API.panel.types.genericLookup:
						onCloseCallLookup();
						break;
					default:
						break;
				}

			};

			const tab = (
				<PanelTab
					ref={(el) => this.tabs['tab_' + panelId] = el}
					active={panel[API.panel.id] === activeId}
					label={label}
					key={'tab_' + panelId}
					interactionId={panelId}
					onActivate={() => onActivate(panelSetId, panelGroupId, panelId)}
					onClose={closeEnabled ? onCloseCallback : null}
					debugModeEnabled={debugModeEnabled}
				/>
			);

			if (_.includes(hiddenTabs, 'tab_' + panelId)) {
				hiddenTabItems.push(tab);
			}

			return tab;
		});

		const classes = classNames({
			'interaction-tabs': true,
			'interaction-tabs--singletab': panelGroupPanels.length === 1,
			'interaction-tabs--customerdata-active': activeType === API.panel.customerdata,
			'interaction-tabs--test-active': activeType === API.panel.test,
			'interaction-tabs--start-shift': _.includes([API.panel.types.startShift, API.panel.types.break], activeType),
			[`interaction-tabs--color-${panelSetColor}`]: !_.isNil(panelSetColor)
		});

		return (
			<div className={classes}>
				<div className="interaction-tabs__visible">{allTabs}</div>

				{
					hiddenTabItems.length > 0 &&
					<PanelTabsSubmenu icon={ICONS.submenu}>{hiddenTabItems}</PanelTabsSubmenu>
				}
			</div>
		);
	}

	static propTypes = {
		intl: intlShape.isRequired,
		panelSetId: PropTypes.string.isRequired,
		panelGroup: PropTypes.object.isRequired,
		panels: PropTypes.object.isRequired,
		activeType: PropTypes.string.isRequired,
		onlyGroup: PropTypes.bool,
		panelSetColor: PropTypes.number,
		onActivate: PropTypes.func,
		onCloseTab: PropTypes.func,
		onCloseTask: PropTypes.func,
		onAddInteraction: PropTypes.func,
		debugModeEnabled: PropTypes.bool
	};

	static defaultProps = {
		panelSetId: '',
		panelGroup: {},
		panels: {},
		activeType: '',
		intl: null
	};
};

const mapStateToProps = (state, ownProps) => {

	const debugModeEnabled = process.env.REACT_APP_LOGGING === "true";
	return {
		debugModeEnabled
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		onActivate: bindActionCreators(ACTIONS.activateTab, dispatch),
		onCloseTab: bindActionCreators(ACTIONS.closeTab, dispatch),
		onCloseCallLookup: bindActionCreators(ACTIONS.requestCancelOutboundCall, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PanelSetPanelTabs));
