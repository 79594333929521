import store from '../services/createStore';
import * as Actions from '../actions';
import { getMessages } from '../selectors';


export const addPersistentNotification = () => {
    fetch(
        `persistentNotification.json?cacheBypass=${new Date().getTime()}`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    )
        .then(function (response) {
            return response.json();
        })
        .then(function (notification) {
            let messages = getMessages(store.getState());
            if (notification.message && (messages.length == 0 || messages.filter(m => m.text === notification.message).length == 0)) {
                store.dispatch(Actions.addMessage({
                    id: (new Date()).getTime(),
                    timestamp: new Date(),
                    text: notification.message,
                    link: ''
                })
                );
            }
        })
}
