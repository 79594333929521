import { call, select, takeLatest } from 'redux-saga/effects';
import * as Actions from '../actions';
import * as Selectors from '../selectors';
import { httpPost } from '../services/http';

/**
 * request to initiate an outbound call
 */
function* requestOutboundTeamsCall() {
	const url = (yield select(Selectors.getBlenderServiceUrl)) + "/Resource/ReserveForCallLookup";
	const authHeader = yield select(Selectors.getAuthHeader);
	try {
		yield call(httpPost, url, null, authHeader);
	} catch (error) {
		console.log(error);
	}
}

/**
 * request to initiate an email lookup
 */
function* requestOutboundEmail() {
	const url = (yield select(Selectors.getBlenderServiceUrl)) + "/Resource/ReserveForEmailLookup";
	const authHeader = yield select(Selectors.getAuthHeader);
	try {
		yield call(httpPost, url, null, authHeader);
	} catch (error) {
		console.log(error);
	}
}

/**
 * request to initiate generic lookup
 */
function* requestGenericLookup() {
	const url = (yield select(Selectors.getBlenderServiceUrl)) + "/Resource/ReserveForLookup";
	const authHeader = yield select(Selectors.getAuthHeader);
	try {
		yield call(httpPost, url, null, authHeader);
	} catch (error) {
		console.log(error);
	}
}

/**
 * request for closing the workItem
 * @param {*} action 
 */
function* closeWorkItem(action) {
	const authHeader = yield select(Selectors.getAuthHeader);
	const url = (yield select(Selectors.getBlenderServiceUrl)) + "/Resource/CompleteWorkItem";
	try {
		yield call(httpPost, url, action.payload, authHeader);
	} catch (error) {
		console.log(error);
	}
}


export default function* () {
	yield takeLatest(Actions.requestOutboundEmail.getType(), requestOutboundEmail);
	yield takeLatest(Actions.requestInitiateOutboundCall.getType(), requestOutboundTeamsCall);
	yield takeLatest(Actions.requestGenericLookup.getType(), requestGenericLookup);
	yield takeLatest(Actions.requestCancelOutboundCall.getType(), closeWorkItem);
	yield takeLatest(Actions.closeWorkItem.getType(), closeWorkItem);

}
