import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import API from '../../api/Api';
import ICONS from '../../data/icons';

import PanelTitleMessages from '../../components/PanelTitle/PanelTitle.messages';
import breakStatusMessages from '../UserStatus/UserStatus.messages';
import messages from './SidebarComposed.messages';

import * as ACTIONS from '../../actions';

import * as Selectors from '../../selectors';

import ActionButton, { BUTTON_STYLE } from '../../components/ActionButton/ActionButton';
import CaseListItem from '../../components/CaseListItem/CaseListItem';
import PanelTitle from '../../components/PanelTitle/PanelTitle';
import Timer from '../../components/Timer/Timer';


import './SidebarComposed.css';

/**
 * Sidebar whichs contains the open panelsets (workitems) You can use this to switch between active (rendered) stages
 */
class SidebarComposed extends Component {

	render() {
		const {
			intl,
			stages,
			taskManagerPanelSet,
			isTaskManagerOpen,
			onOpenTaskManager,
			onCloseTaskManager,
			onActivate,
			currentBreakName,
			onNewTeamsOutboundCall,
			onNewOutboundEmail,
			onGenericLookup,
			canInitiateOutboundConversation,
			canInitiateOutboundEmail,
		} = this.props;
		const { formatMessage } = intl;

		const panelTitleLabel = formatMessage(PanelTitleMessages.PanelTitle_TaskList);

		const stageGroups = stages.map((stage, index) => {
			const stageGroupClasses = classNames({
				'stage-group': true,
				'stage-group--active': stage[API.stage.active]
			});

			return (
				<ul key={'stagegroup_' + stage[API.stage.id]} className={stageGroupClasses}>
					{
						_.map(stage[API.stage.panelSets], (panelSetItem) => {

							const panelSetId = panelSetItem[API.panelSet.id];
							const panelSetColor = panelSetItem[API.panelSet.color];
							const panelSetStatus = panelSetItem[API.panelSet.status];
							const panels = panelSetItem[API.panelSet.panels];
							const taskManagerArrowEnabled = (panelSetItem[API.panelSet.status] === API.panelSet.statuses.inprogress && !(panelSetItem[API.panelSet.taskManagerArrowDisabled] === true));
							let title, subtitle, timer;
							let localTimer = "";
							if (panelSetItem[API.panelSet.createdAt]) {
								localTimer = <Timer size={"small"} autoStart={true} startTime={panelSetItem[API.panelSet.createdAt]} />;
							}

							switch (panelSetStatus) {
								case API.panelSet.statuses.request: {
									//TODO: logout request
									const requestType = panels[0][API.panel.type];
									if (requestType === API.panel.types.pendingLogout) {
										subtitle = formatMessage(breakStatusMessages['UserStatus_' + API.agentStatusTypes.pendingLogout]);
									}
									else if (requestType === API.panel.types.break) {
										if (!currentBreakName) {
											subtitle = formatMessage(breakStatusMessages['UserStatus_' + API.agentStatusTypes.break]);
										}
										else {
											subtitle = currentBreakName
										}
									}
									else if (requestType === API.panel.types.pendingBreak) {
										subtitle = formatMessage(messages.SidebarComposed_PendingBreakSubtitle);
									}

									title = formatMessage(messages.SidebarComposed_Request);
								}
									break;

								case API.panelSet.statuses.userSettings:
									title = formatMessage(messages.SidebarComposed_Request);
									subtitle = formatMessage(messages.SidebarComposed_UserSettings);
									break;

								case API.panelSet.statuses.incoming:
									title = formatMessage(messages.SidebarComposed_Incoming);
									subtitle = formatMessage(messages.SidebarComposed_IncomingWaiting);
									break;

								case API.panelSet.statuses.search:
									title = formatMessage(messages.SidebarComposed_Search);
									break;

								case API.panelSet.statuses.cqManager:
									title = formatMessage(messages.SidebarComposed_CQManager);
									timer = localTimer;
									break;

								case API.panelSet.statuses.inprogress:
									title = panelSetItem[API.panelSet.title];
									timer = localTimer;
									break;

								case API.panelSet.statuses.callLookup:
									title = formatMessage(messages.SidebarComposed_NewVoiceCall);
									timer = localTimer;
									break;

								case API.panelSet.statuses.emailLookup:
									title = formatMessage(messages.SidebarComposed_NewOutboundEmail);
									timer = localTimer;
									break;

								case API.panelSet.statuses.genericLookup:
									title = formatMessage(messages.SidebarComposed_GenericLookup);
									timer = localTimer;
									break;

								case API.panelSet.statuses.supervisor:
									title = formatMessage(messages.SidebarComposed_Supervisor);
									subtitle = null;
									break;

								default:
									title = 'default Title';
									subtitle = 'default SubTitle';
							}

							let counter = panelSetItem[API.panelSet.unreadMessagesCount];

							if (counter === 0) {
								counter = null;
							}
							else {
								counter = panelSetItem[API.panelSet.unreadMessagesCount]
							}

							return (
								<CaseListItem
									panelSetId={panelSetId}
									panelSetColor={panelSetColor}
									panels={panels}
									title={title}
									subtitle={subtitle}
									timer={timer}
									key={'panelSet_' + panelSetId}
									taskManagerPanelSet={taskManagerPanelSet}
									isTaskManagerOpen={isTaskManagerOpen}
									active={panelSetItem[API.panelSet.active]}
									onActivate={() => onActivate(panelSetId)}
									onOpenTaskManager={onOpenTaskManager}
									onCloseTaskManager={onCloseTaskManager}
									taskManagerArrowEnabled={taskManagerArrowEnabled}
									counter={counter}
								/>
							);
						})
					}
				</ul>
			);
		});

		return (
			<nav className="sidebar">
				<PanelTitle icon={ICONS.caselist} label={panelTitleLabel} />
				{
					(stages && stageGroups) &&
					<div className="stage-list scrollbar scrollbar--full-width scrollbar--transparent">
						{stageGroups}
					</div>
				}

				{
					(canInitiateOutboundConversation === true) && <div id='initiateConversationContainer' className="sidebar__newOutboundConversation">
						<ActionButton id="genericLookupButton" clickHandler={onGenericLookup} label={formatMessage(messages.SidebarComposed_GenericLookup)} icon={ICONS.search} buttonStyle={[BUTTON_STYLE.negative, BUTTON_STYLE.raised]} />
						<ActionButton id="teamsNewOutBoundCallButton" clickHandler={onNewTeamsOutboundCall} label={formatMessage(messages.SidebarComposed_NewVoiceCall)} icon={ICONS.phone} buttonStyle={[BUTTON_STYLE.negative, BUTTON_STYLE.raised]} />
						{canInitiateOutboundEmail && <ActionButton id="outboundEmailButton" clickHandler={onNewOutboundEmail} label={formatMessage(messages.SidebarComposed_NewOutboundEmail)} icon={ICONS.email} buttonStyle={[BUTTON_STYLE.negative, BUTTON_STYLE.raised]} />}
						<ActionButton id="newOutboundConversation" icon={ICONS.add} buttonStyle={[BUTTON_STYLE.negative, BUTTON_STYLE.raised]} />
					</div>
				}

			</nav>
		);
	}

	static propTypes = {
		stages: PropTypes.array.isRequired,
		intl: intlShape.isRequired,
		hasSearch: PropTypes.bool,
		isTaskManagerOpen: PropTypes.bool,
		taskManagerPanelSet: PropTypes.string,
		hasWIs: PropTypes.bool,
		onOpenTaskManager: PropTypes.func,
		onCloseTaskManager: PropTypes.func,
		onActivate: PropTypes.func,
		onStagePositionSelected: PropTypes.func,
		currentBreakName: PropTypes.string
	};
}

const mapStateToProps = (state) => {

	const currentPanelSet = state[API.user._key][API.user.currentPanelSet];
	const isTaskManagerOpen = Selectors.getUserInfo(state, API.user.isTaskManagerOpen);
	const taskManagerPanelSet = Selectors.getUserInfo(state, API.user.taskManagerPanelSet);
	const currentBreakName = Selectors.getCurrentBreakName(state);
	const panelSetStages = Selectors.getPanelSetStages(state);
	const stages = _.map(panelSetStages, (stage) => {

		const panelSetIds = stage[API.stage.panelSets];
		const panelSets = Selectors.getPanelSetsHashById(state, panelSetIds);

		const mappedPanelSets = [];

		// Inline customer data
		_.forOwn(panelSets, (panelSetItem, panelSetId) => {
			const currentPanelSetItem = {};

			currentPanelSetItem[API.panelSet.id] = panelSetId;
			currentPanelSetItem[API.panelSet.active] = panelSetId === currentPanelSet;
			currentPanelSetItem[API.panelSet.color] = panelSetItem[API.panelSet.color];
			currentPanelSetItem[API.panelSet.status] = panelSetItem[API.panelSet.status];
			currentPanelSetItem[API.panelSet.workItemId] = panelSetItem[API.panelSet.workItemId];
			currentPanelSetItem[API.panelSet.panels] = [];
			currentPanelSetItem[API.panelSet.title] = Selectors.getCustomerName(state, panelSetItem[API.panelSet.workItemId]);
			currentPanelSetItem[API.panelSet.createdAt] = panelSetItem[API.panelSet.createdAt];

			currentPanelSetItem[API.panelSet.taskManagerArrowDisabled] = panelSetItem[API.panelSet.taskManagerArrowDisabled];
			//Inline panels, not interactions! They haven't been renamed yet
			const panelIds = Selectors.getInteractionIds(state, panelSetId);

			_.forEach(panelIds, (panelId) => {

				const panel = Selectors.getInteraction(state, panelId);

				if (panel) {
					currentPanelSetItem[API.panelSet.panels].push({
						[API.panel.id]: panel[API.panel.id],
						[API.panel.type]: panel[API.panel.type],
						//Todo: rewrite this logic: panels should not have channelId property just because a react component down the tree needs this data
						//Write a selector that takes in a panelId, checks if its a conversation related one and calculate the icon based on that and the panelType
						[API.panel.channelId]: panel[API.panel.conversationId] ? Selectors.getConversationChannelType(state, panel[API.panel.conversationId], panelSetItem[API.panelSet.workItemId]) : null,
						[API.panel.content]: panel[API.panel.content]
					});
				}
			});

			if (currentPanelSetItem[API.panelSet.workItemId] !== null && currentPanelSetItem[API.panelSet.workItemId] !== undefined) {
				currentPanelSetItem[API.panelSet.unreadMessagesCount] = Selectors.getUnreadMessageCountForPanelSet(state, panelSetId);
			}

			mappedPanelSets.push(currentPanelSetItem);
		});

		return {
			[API.stage.id]: stage[API.stage.id],
			[API.stage.active]: _.includes(panelSetIds, currentPanelSet),
			[API.stage.panelSets]: mappedPanelSets
		};
	});

	const canInitiateOutboundConversation = !(Selectors.getResourceReservedForCallLookupOrCall(state));
	const canInitiateOutboundEmail = (Selectors.getAgentCapabilities(state).includes(API.agentProperties.capabilityTypes.email));

	return {
		stages,
		isTaskManagerOpen,
		taskManagerPanelSet,
		currentBreakName,
		canInitiateOutboundConversation,
		canInitiateOutboundEmail
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onOpenTaskManager: bindActionCreators(ACTIONS.openTaskManager, dispatch),
		onCloseTaskManager: bindActionCreators(ACTIONS.closeTaskManager, dispatch),
		onActivate: bindActionCreators(ACTIONS.activatePanelSet, dispatch),
		onNewTeamsOutboundCall: bindActionCreators(ACTIONS.requestInitiateOutboundCall, dispatch),
		onNewOutboundEmail: bindActionCreators(ACTIONS.requestOutboundEmail, dispatch),
		onGenericLookup: bindActionCreators(ACTIONS.requestGenericLookup, dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SidebarComposed));