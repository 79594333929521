import React, { PureComponent, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditCaseLayout from '../EditCaseLayout/EditCaseLayout';
import Label from '../../components/Label/Label';
import {
  checkBackendState,
  wasLogOutRequested,
  checkBackendLoggedIn,
  checkReceivedBackendStateAtLeastOnce,
  getOidcUser
} from '../../selectors';
import WebSiteNotification from '../WebSiteNotification/WebSiteNotification';
import userManager from "../../services/userManager";
import './MainLayout.css';
import { addPersistentNotification } from '../../services/notificationReader'
import LoadingScreen from '../LoadingScreen/LoadingScreen';


/**
 * This component makes sure that the client is connected to the backend and is logged in
 * Then it renders the EditCaseLayout
 * If logout was requested and executed, then this redirects to the LogoutPage
 */
const MainLayout = (props) => {
  const { connectedAtLeastOnce, loggedin, logoutRequested, agentStateKnown, oidcUser } = props;

  useEffect(() => {
    const interval = setInterval(addPersistentNotification, 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(
    () => {
      if (!oidcUser && !logoutRequested) {
        userManager.signinRedirect()
      }
    },
    [oidcUser, logoutRequested, userManager]
  )

  useEffect(
    () => {
      if (!loggedin && logoutRequested) {
        // logout was requested once, now the agent is logged out
        //time to redirect
        userManager.signoutRedirect();
      }
    },
    [logoutRequested, userManager, loggedin]
  )
  if (!connectedAtLeastOnce) {
    //there was never a valid connection before
    return (
      <LoadingScreen />
    );
  }
  //we are connected
  if (agentStateKnown) {
    //we know the agent's state
    if (loggedin) {
      //Agent is logged in with a known state
      return (
        <div>
          <WebSiteNotification />
          <EditCaseLayout />
        </div>
      );
    }
    if (!logoutRequested) {
      //agent did not request the logout yet, so wait for the login process to finish
      return (
        <div>
          <LoadingScreen />
        </div>
      );
    }
    //agent state known -> logged out and logout requested
    return (
      <div>
        <LoadingScreen />
      </div>
    );;
  }

  //Agent state unknown
  return (
    <div>
      <Label text={"Agent State Unknown"} />
    </div>
  );
}

MainLayout.propTypes = {
  loggedin: PropTypes.bool.isRequired,
  logoutRequested: PropTypes.bool.isRequired,
};

MainLayout.defaultProps = {
  loggedin: false,
  logoutRequested: false,
};


const mapStateToProps = (state) => {

  const connectedAtLeastOnce = checkReceivedBackendStateAtLeastOnce(state);
  const loggedin = checkBackendLoggedIn(state);
  const logoutRequested = wasLogOutRequested(state);
  var agentStateKnown = false;
  if (connectedAtLeastOnce) {
    agentStateKnown = checkBackendState(state);
  }
  const oidcUser = getOidcUser(state);


  return {
    connectedAtLeastOnce,
    loggedin,
    logoutRequested,
    agentStateKnown,
    oidcUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
