import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import API from '../../api/Api';

import * as Actions from '../../actions';
import * as Selectors from '../../selectors';
import { getDispositionCodes, getPanelSet, getUserInfo } from '../../selectors';

import ActionButton, { BUTTON_STYLE } from '../../components/ActionButton/ActionButton';
import Dropdown, { DROPDOWN_STYLE } from '../../components/Dropdown/Dropdown';
import TaskManagerLayout from '../../layouts/TaskManagerLayout/TaskManagerLayout';
import Waves from '../Waves/Waves';

import TextField, { TEXTFIELD_STYLE } from '../../components/TextField/TextField';
import Timer from '../../components/Timer/Timer';
import messages from './TaskManager.messages';
/**
 * The task manager you can open via click on a stage at the side or at the specific button.
 * You can close tasks here.
 */
class TaskManager extends Component {

	constructor(props) {
		super(props);
		this.state = {
			notes: {},
			dispositionCode: {}
		};
		this.waves = <Waves />;
		this.handleUnload = this.handleUnload.bind(this);
	}

	handleTextareaOnChange = (e) => {
		const newStateNotes = { ...this.state.notes };
		newStateNotes[this.props.taskManagerPanelSet] = e;
		this.setState({
			notes: newStateNotes
		});
	};

	setDispositionCode = (e) => {
		const newStateCodes = { ...this.state.dispositionCode };
		newStateCodes[this.props.taskManagerPanelSet] = e.target.value;
		this.setState({
			dispositionCode: newStateCodes
		});
	}

	handleCloseWorkItemButtonClick = () => {

		const panelSetId = this.props.taskManagerPanelSet;
		this.props.onCloseWorkItem(this.props.workItemId, panelSetId in this.state.dispositionCode ? this.state.dispositionCode[panelSetId] : null, panelSetId in this.state.notes ? this.state.notes[panelSetId] : null)

		this.props.onCloseTaskManager();
	}

	componentDidMount() {
		window.addEventListener('beforeunload', this.handleUnload);
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.handleUnload);
	}

	handleUnload(e) {
		e.returnValue = '';
	}



	render() {
		const { intl, availableDispositionCodes, taskManagerPanelSet, activePanelSet, isTaskManagerOpen, onOpenTaskManager, onCloseTaskManager, afterworkStartedAt } = this.props;
		const { formatMessage } = intl;
		const afterWorkTimer = afterworkStartedAt == null || undefined ? null : <Timer startTime={afterworkStartedAt} key={afterworkStartedAt} size={"top"} autoStart={true} />

		//IncomingAlert react container has been removed from project, but taskManagerLayout wasn't purged ouf it's reference, in case we wan't to readd it in the future
		const options = {};
		let forceCompletionCodeSelection = false;
		if (taskManagerPanelSet in availableDispositionCodes) {
			forceCompletionCodeSelection = availableDispositionCodes[taskManagerPanelSet].forceSelection;
			availableDispositionCodes[taskManagerPanelSet].codes.forEach(element => {
				options[element] = element;
			});
		}
		const codesAreSpecified = Object.getOwnPropertyNames(options).length > 0
		const notSpecified = formatMessage(messages.TaskManager_DispositionCodeNotSpecified);

		const selectedDispositionCode = this.state.dispositionCode[taskManagerPanelSet];
		const closeWIButtonIsEnabled = forceCompletionCodeSelection
			? !!afterworkStartedAt && (!!selectedDispositionCode || !codesAreSpecified)
			: !!afterworkStartedAt;

		const closePanelSetButton = closeWIButtonIsEnabled
			? <ActionButton clickHandler={this.handleCloseWorkItemButtonClick} text={formatMessage(messages.TaskManager_CloseTask)} buttonStyle={[BUTTON_STYLE.accept, BUTTON_STYLE.raised]} />
			: <ActionButton disabled={true} label={formatMessage(messages.TaskManager_DisabledCloseTask)} text={formatMessage(messages.TaskManager_CloseTask)} buttonStyle={[BUTTON_STYLE.accept, BUTTON_STYLE.raised]} />

		if (activePanelSet == "supervisor") {
			return null;
		}
		return (
			<TaskManagerLayout timer={afterWorkTimer} title={formatMessage(messages.TaskManager_Title)} incomingAlert={null} waves={this.waves} eventTypes={['click', 'touchend']} open={isTaskManagerOpen} closePanelSetButton={closePanelSetButton} onOpenTaskManager={() => onOpenTaskManager(activePanelSet)} onCloseTaskManager={onCloseTaskManager}>
				{/* Note: a react component HAS to be passed as a children, hence the empty div */}

				<div >


					{(codesAreSpecified) ? (<Dropdown
						options={options}
						defaultValue={notSpecified}
						label={formatMessage(messages.TaskManager_DispositionCodeLabel)}
						styles={[DROPDOWN_STYLE.fullWidth]}
						onChange={this.setDispositionCode}
						value={selectedDispositionCode || notSpecified}
					/>)
						: (<Dropdown
							options={{ notSpecified: notSpecified }} text={notSpecified} label={formatMessage(messages.TaskManager_DispositionCodeLabel)} styles={[DROPDOWN_STYLE.fullWidth]}
						/>)
					}

					<TextField
						value={this.state.notes[taskManagerPanelSet] || ""}
						onChange={this.handleTextareaOnChange}
						placeholder={formatMessage(messages.TaskManager_AddNotes)}
						styles={[TEXTFIELD_STYLE.raised, TEXTFIELD_STYLE.fullWidth]}
					/>
				</div>
			</TaskManagerLayout>
		);
	}

	static propTypes = {
		intl: intlShape.isRequired,
		hidden: PropTypes.bool,
		panelSetStatus: PropTypes.string,
		incomingTask: PropTypes.bool,
		activePanelSet: PropTypes.string,
		taskManagerPanelSet: PropTypes.string,
		isTaskManagerOpen: PropTypes.bool,
		onOpenTaskManager: PropTypes.func,
		onCloseTaskManager: PropTypes.func,
		onCloseWorkItem: PropTypes.func,
		lastStateChangeDate: PropTypes.number
	};
}

const mapStateToProps = (state) => {

	const activePanelSet = getUserInfo(state, API.user.currentPanelSet);
	const taskManagerPanelSet = getUserInfo(state, API.user.taskManagerPanelSet);
	const isTaskManagerOpen = getUserInfo(state, API.user.isTaskManagerOpen);

	const panelSetItem = getPanelSet(state, taskManagerPanelSet || activePanelSet);
	const panelSetStatus = panelSetItem ? panelSetItem[API.panelSet.status] : null;
	const incomingTask = _.isObject(state[API.user._key][API.user.incomingInteraction]);
	const availableDispositionCodes = getDispositionCodes(state);


	if (panelSetItem) {
		let afterworkStartedAt = null;

		const workItemId = panelSetItem[API.panelSet.workItemId];

		if (workItemId) {
			const workItem = Selectors.getWorkItemsV2(state)[workItemId];
			if (workItem && workItem[API.workItemV2.workItemState] === API.workItemV2.workItemStates.afterwork) {
				afterworkStartedAt = Date.parse(workItem[API.workItemV2.lastStateChangeDate]);
			}
		}

		return {
			activePanelSet,
			isTaskManagerOpen,
			taskManagerPanelSet,
			panelSetStatus,
			incomingTask,
			availableDispositionCodes,
			workItemId,
			afterworkStartedAt
		};
	}

	return {
		activePanelSet,
		isTaskManagerOpen,
		taskManagerPanelSet,
		panelSetStatus,
		incomingTask,
		availableDispositionCodes,
		closeWIButtonIsEnabled: false
	};


};

const mapDispatchToProps = (dispatch) => {
	return {
		onOpenTaskManager: bindActionCreators(Actions.openTaskManager, dispatch),
		onCloseTaskManager: bindActionCreators(Actions.closeTaskManager, dispatch),
		onCloseWorkItem: bindActionCreators(Actions.closeWorkItem, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TaskManager));