import CustomerDataFragment from "@buzzeasy/customer-data-fragment-2";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions';
import * as Selectors from '../../selectors';

/**
 * Redux container for the customerView react component. Used when a customerview panel is rendered.
 */
class CustomerPanel extends Component {

  constructor(props) {
    super(props);
    this.fragmentRef = React.createRef();

    this.onSwap = this.onSwap.bind(this);
  }

  onSwap(newCustomerId) {
    //todo: rewrite this with redux-saga

    const { workflowApiUrl, conversationId, accessToken } = this.props;
    if (!conversationId) {
      return;
    }
    fetch(`${workflowApiUrl}/api/conversation/${conversationId}/setData`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify([{ Key: 'CustomerId', Value: newCustomerId }]),
    });
  }

  render() {
    const {
      customerId,
      accessToken,
      aksUrl,
      isConversationClosed,
      workItem,
      hideSensitiveAgentInformation,
    } = this.props;

    const isCampaignTask = workItem.primaryChannelType === 'virtual';

    return <div style={{ "height": "calc(100% - 30px)", "zIndex": "0" }}>
      <CustomerDataFragment
        apiBaseUrl={aksUrl}
        token={accessToken}
        businessUnitId={workItem.businessUnitId}
        customerId={customerId}
        disableSchedule={isCampaignTask}
        onSwapCustomer={isConversationClosed ? undefined : this.onSwap}
        onCustomerRetrieved={this.props.onCustomerDataReceived}
        featureFlags={{ hideSensitiveAgentInformation }}
      />
    </div>;
  }

  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    aksUrl: PropTypes.string.isRequired,
    customerId: PropTypes.string,
    conversationId: PropTypes.string,
    isConversationClosed: PropTypes.bool,
    panelId: PropTypes.string.isRequired,
    workItem: PropTypes.object,
    hideSensitiveAgentInformation: PropTypes.bool.isRequired,
  };
}

const mapStateToProps = (state, ownProps) => {
  const { customerId, conversationId } = Selectors.getDataForCustomerPanel(state, ownProps.panelId);
  const accessToken = Selectors.backEndAccessToken(state);
  const aksUrl = Selectors.getAksApiUrl(state);
  const workflowApiUrl = Selectors.getWorkflowUrl(state);
  const sipUrl = Selectors.getSIPConnectorUrl(state);
  const isConversationClosed = Selectors.isConversationClosed(state, ownProps.panelId);
  const hideSensitiveAgentInformation = Selectors.getDisplaySettings(state)?.hideSensitiveAgentInformation ?? false;

  return {
    accessToken,
    workflowApiUrl,
    aksUrl,
    customerId,
    conversationId,
    sipUrl,
    isConversationClosed,
    hideSensitiveAgentInformation,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    onCustomerDataReceived: bindActionCreators(Actions.customerReceived, dispatch),
    onError: bindActionCreators(Actions.addMessage, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPanel);
