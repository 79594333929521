import DEFAULT_LANGUAGE from './languages';
import DEFAULT_THEME from './themes';

import API from '../api/Api';

import ReduxModels from '../api/ReduxModels';

export const UI_PROCESS = {
  stagePositionSelect: 'stagePositionSelect'
};

export default {

  [ReduxModels.nodes.uiState]: {
    [API.uiState.translations]: null,
    [API.uiState.initialDataLoaded]: false,
    [API.uiState.uiProcess]: null,
    [API.uiState.wavePhase]: 0,
    [API.uiState.overlay]: false,
    [API.uiState.loadSuperVisorBreakCard]: false,
  },

  [ReduxModels.nodes.login]: {
    [API.login.phase]: null
  },

  [ReduxModels.nodes.panelSet]: {},
  [ReduxModels.nodes.panel]: {},

  [ReduxModels.nodes.user]: {
    [API.user.language]: DEFAULT_LANGUAGE,
    [API.user.theme]: DEFAULT_THEME,
    [API.user.logoutRequested]: false,
    [API.user.isSupervisor]: false,
    [API.user.accessToken]: undefined,
    [API.user.name]: "default user",

    [API.user.stages]: []
  },

  [ReduxModels.nodes.backEnd]: {
    [API.backEnd.stateUrl]: null,
    [API.backEnd.loginUrl]: null,
    [API.backEnd.failedAttempt]: 0
  },

  [ReduxModels.nodes.breakNames]: [],

  [ReduxModels.nodes.notifications]: {
    [API.notifications.isFocused]: true,
    [API.notifications.lastFocusDate]: 0
  },

  [ReduxModels.nodes.environment]: null,

  [ReduxModels.nodes.scripting]: {},

  [ReduxModels.nodes.chat]: {},

  [ReduxModels.nodes.customers]: {},

  [ReduxModels.nodes.messages]: [],
};

export const LOGIN_PHASE = {
  init: 'init',
  login: 'login'
};