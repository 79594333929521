import { createUserManager } from 'redux-oidc';

const userManagerConfig = {
  client_id: process.env.REACT_APP_CLIENT_ID,
  monitorSession: false,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/#/callback`,
  popup_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/#/popup`,
  response_type: 'code',
  scope: 'openid profile email phone',
  authority: process.env.REACT_APP_GEO_AUTH_URL,
  acr_values: 'idp:aad.oidc',
  loadUserInfo: true,
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/#/logout`
};

const userManager = createUserManager(userManagerConfig);

export default userManager;