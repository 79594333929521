
import * as microsoftTeams from '@microsoft/teams-js';
import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import LoadingScreen from '../LoadingScreen/LoadingScreen';

function Authorize() {
    useEffect(
        () => {
            microsoftTeams.initialize();
            microsoftTeams.getContext(function (context) {
                const state = uuid().toString();
                localStorage.setItem('auth.state', state);
                const queryParams = {
                    client_id: 'client.agentclient.teams',
                    response_type: "id_token token",
                    response_mode: "fragment",
                    scope: 'openid profile email phone',
                    nonce: uuid().toString(),
                    state: state,
                    acr_values: 'idp:aad.oidc',
                    login_hint: context.loginHint,
                    loadUserInfo: true,
                    redirect_uri: `${window.location.origin}/#/cb?`.replace('#', '%23'),
                };
                const queryString = Object.entries(queryParams).map(([key, value]) => `${key}=${value}`).join('&');

                const authorizeEndpoint = `${process.env.REACT_APP_GEO_AUTH_URL}/connect/authorize?${queryString}`;
                window.location.assign(authorizeEndpoint);
            });
        },
        []
    );

    return <LoadingScreen />;
}

export default Authorize;