import { createReducer } from 'redux-act';
import * as Actions from '../../actions';


export default createReducer({
    [Actions.customerReceived]: (state, customer) => {
        const newState = { ...state };

        newState[customer.customerId] = customer;

        return newState;
    },
}, {});

