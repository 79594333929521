import { WebRtcClient } from "@buzzeasy/web-rtc-client";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect } from 'react-redux';
import * as Selectors from '../../selectors';

export const WebRtcContext = React.createContext();

const ringtone = '/audio/ringtone_lg_kaleidoscope.ogg';

const WebRtcProvider = ({ token, apiBaseUrl, children }) => {
  const ringtoneRef = useRef();

  const [client, setClient] = useState();
  const [isRinging, setIsRinging] = useState(false);

  const createWebRtcClient = useCallback(
    (audioElement) => {
      setClient(curr => curr ? curr : new WebRtcClient(audioElement, {
        onCallReceived: () => { audioElement.play(); setIsRinging(true); },
        onCallHangup: () => { audioElement.pause(); setIsRinging(false); },
        onCallAnswered: () => { setIsRinging(false); }
      }));
      ringtoneRef.current = audioElement;
    },
    [],
  );

  useEffect(
    () => {
      if (!token || !apiBaseUrl)
        return;
      client?.connect(token, apiBaseUrl);
    },
    [token, apiBaseUrl, client],
  );

  const contextValue = useMemo(
    () => ({
      isRinging,
      webRtcClient: client,
    }),
    [isRinging, client],
  );

  return (<>
    <audio id="webRtcAudio" ref={createWebRtcClient} loop src={ringtone} style={{ display: 'none' }} />
    {client && <WebRtcContext.Provider value={contextValue}>
      {children}
    </WebRtcContext.Provider>}
  </>);
}

const mapStateToProps = (state) => {
  const token = Selectors.backEndAccessToken(state);
  const apiBaseUrl = Selectors.getAksApiUrl(state);

  return {
    token,
    apiBaseUrl,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WebRtcProvider);