import { PureComponent } from "react";
import './LoadingScreen.css';

class LoadingScreen extends PureComponent {
    render(){
        return (
            <div className='loading-screen-container'>
            <div className='loading-screen-pulse-container'>
              <div className='loading-screen-pulse'></div>
            </div>
            <div className='loading-screen-logo-container'>
              <svg className="loading-screen-logo" viewBox="0 0 43.381 43.381">
                <circle cx="18.691" cy="18.691" r="18.691" fill="#DADAFF" />
                <path
                  d="M25.24,21.748a12.449,12.449,0,0,1-.028,3.407,19.308,19.308,0,0,1-.71,3.379,18.109,18.109,0,0,1-.681,2.016c-.17.369-.341.738-.483,1.079a.456.456,0,0,1-.539.284c-4.088-.767-5.905-4.855-4.514-8.631a5.522,5.522,0,0,1,3.975-3.634,2.475,2.475,0,0,1,1.959.341A2.68,2.68,0,0,1,25.24,21.748Zm18.142-.057A21.691,21.691,0,1,1,21.719,0,21.689,21.689,0,0,1,43.381,21.691ZM32.621,28.9c-.284-.2-.539-.057-.909.4a8.33,8.33,0,0,1-4,2.584c-.227.057-.2-.085-.142-.17a3.534,3.534,0,0,0,.312-.653c.17-.4.454-1.05.653-1.618.227-.568.426-1.136.426-1.136a13.326,13.326,0,0,0,.539-4.713,7.287,7.287,0,0,0-1.334-4.173,4.2,4.2,0,0,0-4.032-1.7h0a6.542,6.542,0,0,0-3.492,1.5c-.085.057-.17,0-.114-.085.483-.909.994-1.817,1.448-2.754a17.4,17.4,0,0,0,2.073-5.536,6.669,6.669,0,0,0-.2-3.577,2.236,2.236,0,0,0-2.158-1.42,2.485,2.485,0,0,0-.454.057,3.541,3.541,0,0,0-1.5.823,11.454,11.454,0,0,0-2.726,3.833c-.227.511-.454,1.022-.653,1.533a35.844,35.844,0,0,0-1.987,7.013c-.057.256-.085.539-.142.795v.028a36.5,36.5,0,0,0-.71,7.013,14.925,14.925,0,0,0,1.647,7.438A6,6,0,0,0,17.29,36.71a5.237,5.237,0,0,0,.994.426,5.982,5.982,0,0,0,4.968-.738,10.4,10.4,0,0,0,3.237-2.924.619.619,0,0,1,.454-.256,8.479,8.479,0,0,0,5.479-2.924C32.82,29.725,32.962,29.158,32.621,28.9ZM22.173,32.99c-.2-.057-.4-.085-.6-.142a8.784,8.784,0,0,1-4-2.5c-.114-.114-.256-.142-.256.227a11.169,11.169,0,0,0,.17,1.363A6.7,6.7,0,0,0,18,33.558a3.736,3.736,0,0,0,.539.823,1.8,1.8,0,0,0,2.47.256,6.373,6.373,0,0,0,1.334-1.278C22.429,33.161,22.344,33.019,22.173,32.99ZM18.483,19.022q.681-1.065,1.278-2.129a23.689,23.689,0,0,0,1.647-3.8c.114-.341.2-.653.312-.994v-.028a13.428,13.428,0,0,0,.539-2.981V8.972a4.147,4.147,0,0,0-.085-1.022.516.516,0,0,0-.4-.4c-.227-.028-.341.114-.454.256a3.12,3.12,0,0,0-.256.4c-.028.028-.028.085-.057.114a10.375,10.375,0,0,0-.767,1.959A59.087,59.087,0,0,0,18.6,17.432c-.085.511-.17,1.022-.256,1.5C18.284,19.079,18.4,19.136,18.483,19.022Z"
                  fill="#4D61C4" />
              </svg>
            </div>
          </div>
        );
    }
}

export default (LoadingScreen);