import API from '../api/Api';

export const backEndProps = (state) => state[API.backEnd._key];

export const backEndStateUrl = (state) => backEndProps(state)[API.backEnd.stateUrl]; // cleanup
export const backEndLastStateTimestamp = (state) => backEndProps(state)[API.backEnd.lastStateTimestamp];
export const backEndLoginUrl = (state) => backEndProps(state)[API.backEnd.loginUrl]; // cleanup
export const backEndAIKey = (state) => backEndProps(state)[API.backEnd.instrumentationKey]; // cleanup
export const getSignalR = (state) => backEndProps(state)["signalR"];

export const getWorkItemById = (state, workItemId) => backEndProps(state).stateData.workItems[workItemId];